import BaseModel from './BaseModel';
import firebase from 'firebase/app';
import 'firebase/storage';
import Chance from "chance";
//import Vue from 'vue';
//import lodash from 'lodash';
import {DataConverter as ExtraDataConverter, Extra as ExtraConverter} from '@/FirestoreDataConverters/Extra.js';


export default class Extra extends BaseModel{
  
  constructor(options) {
    super(options);
  }
  getAll() {
    //return this.db.collection('extras').withConverter(ExtraDataConverter);
    return this.db.collection('extras').orderBy('order', 'asc').withConverter(ExtraDataConverter);
  }
  getActives() {
    return this.db.collection('extras')
      .where('status', '==', 'active')
      .withConverter(ExtraDataConverter);
  }
  add(data) {
    console.log('data', data);
    
    return this.db.collection('extras').withConverter(ExtraDataConverter).add(new ExtraConverter(data));
  }
  findById(docId) {
    return this.db.collection('extras').doc(docId).withConverter(ExtraDataConverter);
  }
  update(docId, data) {
    return this.db.collection('extras').doc(docId).update(data);
  }
  uploadImage(extraId, image) {
    let chance = new Chance();
    let random = chance.string({
      length: 5,
      //alpha: true,
      pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    });
    let splitNameFile = image.name.split('.');
    let name = splitNameFile[0];
    let extension = splitNameFile[splitNameFile.length - 1];
    let fullpath = 'extras/'+extraId+'/'+name+'-'+random+'.'+extension;
    return {
      image:{fullpath:fullpath,name:name},
      ref:firebase.storage().ref(fullpath).put(image)
    };
  }
  delete(id) {
    return new Promise((resolve, reject) => {
      this.db.collection('extras').doc(id).get().then(snapDoc => {
        let batch = this.db.batch();
        let promises = [];
        
        promises.push(snapDoc.ref.collection('extraLanguages').get())
        promises.push(snapDoc.ref.collection('extraImages').get())

        Promise.all(promises).then(values => {
          let languages = values[0];
          let images = values[1];
          languages.forEach(doc => {
            batch.delete(this.db.collection('extras').doc(id).collection('extraLanguages').doc(doc.id));
          })
          let promisesImages = [];
          images.forEach(doc => {
            promisesImages.push(firebase.storage().ref(doc.data().fullpath).delete())
            batch.delete(this.db.collection('extras').doc(id).collection('extraImages').doc(doc.id));
          })
          Promise.all(promisesImages).then(() => {
            batch.delete(snapDoc.ref);
            batch.commit().then(() => resolve('ok'))
            .catch(error => reject(error))
          })
          .catch(error =>reject(error))
        })
        .catch(error => reject(error))
      })
    })
  }
  deleteImage(extraId, image) {
    return new Promise((resolve, reject) => {
      firebase.storage().ref(image.fullpath).delete().then(() => {
        this.db.collection('extras').doc(extraId)
          .collection('extraImages').doc(image.id)
          .delete()
          .then(() => resolve('ok'))
          .catch(error => reject(error))
      })
      .catch(error => reject(error))
    })
  }
  getAvailablesByPanelWithImage(panel) {
    return new Promise((resolve, reject) => {
      this.db.collection('extras')
      .where('status', '==', 'active')
      .where('panels.'+panel, '==', panel)
      .orderBy('order', 'asc')
      .withConverter(ExtraDataConverter)
      .get()
      .then(async (snap) => {
        let extras = []
        for (let index = 0; index < snap.docs.length; index++) {
          const snapExtras = await this.db.collection('extras')
            .doc(snap.docs[index].id)
            .collection('extraImages')
            .get()
          let images = []
          snapExtras.forEach((imageDoc) => {
            images.push(imageDoc.data())
          })
          extras.push({images: images, ...snap.docs[index].data()})
        }
        /*snap.forEach(async (i) => {
          const images = await this.db.collection('extras')
            .doc(i.id)
            .collection('extraImages')
            .get()
          extras.push({images: images.docs, ...i.data()})
        })*/
        resolve(extras)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
        reject(error)
      })
    })
    /*return this.db.collection('extras')
    .where('status', '==', 'active')
      .where('panels.'+panel, '==', panel)
      .orderBy('order', 'asc')
      .withConverter(ExtraDataConverter);*/
  }
}