class Extra {
  id;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.category = data.category;
    this.categoryId = data.categoryId;
    this.localAdult = data.localAdult;
    this.localAdultShared = data.localAdultShared;
    //this.localElderly = data.localElderly;
    this.localChild = data.localChild;
    //this.localInfant = data.localInfant;
    this.nationalAdult = data.nationalAdult;
    this.nationalAdultShared = data.nationalAdultShared;
    //this.nationalElderly = data.nationalElderly;
    this.nationalChild = data.nationalChild;
    //this.nationalInfant = data.nationalInfant;
    this.foreignAdult = data.foreignAdult;
    this.foreignAdultShared = data.foreignAdultShared;
    //this.foreignElderly = data.foreignElderly;
    this.foreignChild = data.foreignChild;
    //this.foreignInfant = data.foreignInfant;
    this.type = data.type;
    this.status = data.status;
    this.order = data.order;
    this.panels = data.panels;
  }
}
var DataConverter = {
  toFirestore: function(item) {
    return {
      name : item.name,
      category : item.category,
      categoryId : item.categoryId,
      localAdult : item.localAdult,
      localAdultShared : item.localAdultShared || null,
      //localElderly : item.localElderly,
      localChild : item.localChild,
      //localInfant : item.localInfant,
      nationalAdult : item.nationalAdult,
      nationalAdultShared : item.nationalAdultShared || null,
      //nationalElderly : item.nationalElderly,
      nationalChild : item.nationalChild,
      //nationalInfant : item.nationalInfant,
      foreignAdult : item.foreignAdult,
      foreignAdultShared : item.foreignAdultShared || null,
      //foreignElderly : item.foreignElderly,
      foreignChild : item.foreignChild,
      //foreignInfant : item.foreignInfant,
      status : item.status,
      order : item.order,
      panels: item.panels
    }
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id
    return new Extra(data)
  }
}
export {Extra, DataConverter} 
