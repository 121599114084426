class ExtraImage {
  id;
  constructor(data) {
    this.id = data.id;
    this.url = data.url;
    this.name = data.name;
    this.fullpath = data.fullpath;
  }
}
var DataConverter = {
  toFirestore: function(item) {
    return {
      url : item.url,
      name : item.name,
      fullpath : item.fullpath,
    }
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id
    return new ExtraImage(data)
  }
}
export {ExtraImage, DataConverter} 