<template>
  <div>
    <portal to="title-page">
      <h1>Extra: {{extra ? extra.name : ''}}</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title >
        <v-icon
          large
          left
        >
          {{mdiImageMultiple}}
        </v-icon>
        <span class="title font-weight-light">Imagenes</span>
        <v-btn small @click="$router.push({path:`/backend/admin/extras/${$route.params.id}/edit`})" color="primary" class="ml-auto"><v-icon>{{mdiPencil}}</v-icon> Informacion</v-btn>
        <v-btn small class="ml-2" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table v-if="items != null"
          :headers="tableHeaders"
          :items="items"
          item-key="id"
          fixed-header
          class="elevation-1"
        >
          <!-- show-select -->
          <!-- :single-select="singleSelect" -->
          <template v-slot:top>
            <v-progress-linear v-if="uploadValue !== null" :value="uploadValue"></v-progress-linear>
            <div class="table-top-bar">
              <loading :active.sync="myLoading.isLoading" :is-full-page="myLoading.fullPage"></loading>
              <div class="d-flex">
                <div class="file-input-width">
                  <v-file-input prepend-icon="mdi-camera" v-model="image" accept="image/*" label="File input"></v-file-input>
                </div>
                <div class="pl-3">
                  <v-btn color="primary" :disabled="!image" class="mt-3" @click="upload">Subir</v-btn>
                </div>

              </div>
            </div>
          </template>
          <template v-slot:item.img="{ item }">
            <v-img :src="item.url" aspect-ratio="1.7"></v-img>
          </template>>
          <template v-slot:item.actions="{ item }">
            <v-icon 
              class="mr-2"
              color="red"
              @click="deleteItem(item)"
            >
              {{mdiDelete}}
            </v-icon>
            <a :href="item.url" target="_blank">
              <v-icon 
                class="mr-2"
              >
                {{mdiOpenInNew}}
              </v-icon>
            </a>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <v-card-actions>
      </v-card-actions> -->
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Extra from "@/models/Extra.js";
import Loading from 'vue-loading-overlay';
import {DataConverter as ExtraImageDataConverter} from "@/FirestoreDataConverters/ExtraImage.js";
import { mdiDelete, mdiClose, mdiArrowLeft, mdiPencil, mdiImageMultiple, mdiOpenInNew } from '@mdi/js';

//import lodash from 'lodash';
export default {
  name: "ExtraEdit",
  components: {
      Loading
  },
  data() {
    return {
      mdiDelete:mdiDelete,
      mdiClose:mdiClose,
      mdiArrowLeft:mdiArrowLeft,
      mdiPencil:mdiPencil,
      mdiImageMultiple:mdiImageMultiple,
      mdiOpenInNew :mdiOpenInNew,
      ////////////////////////////////////////////////////////////////////////////
      //languageModel:new Language,
      extraModel:new Extra,
      extra:null,
      uploadValue:null,
      image:null,
      imageData:null,
      items:[],
      tableHeaders:[
        {
          text: 'Imagen',
          align: 'start',
          sortable: true,
          value: 'img',
          width:'200px',
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false, align:'end' },
      ],
      ref:null,
      snackbar:{
        show:false,
        color:'',
        message:''
      },
      myLoading:{
        isLoading:false,
        fullPage:false
      }
    }
  },
  methods:{
    upload() {
      if (this.image) {
        this.myLoading.isLoading = true;
        let upload = this.extraModel.uploadImage(this.$route.params.id, this.image);
        upload.ref.on(`state_changed`, snapshot=> {
            this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          },
          error => {
            console.log(error.message);
            this.showSnackbarError('Error al subir la imagen')
          },
          ()=>{
            this.uploadValue = 100;
            upload.ref.snapshot.ref.getDownloadURL().then((url)=>{
              this.ref.collection('extraImages').add({url:url, name:upload.image.name, fullpath:upload.image.fullpath}).then(() => {
                this.uploadValue = null;
                this.myLoading.isLoading = false;
                this.image = null;
                this.showSnackbarSuccess('La imagen se subio correctamente')
              })
              .catch(error => {
                console.log('error', error);
                this.showSnackbarError('Error al agregar la imagen en la coleccion');
              })
            });
          }
        );
      }
    },
    deleteItem(item) {
      this.$dialog.confirm(`¿Desea eliminar la imagen <strong>${item.name}</strong>? se eliminara permanentemente`, {
        loader: true // default: false - when set to true, the proceed button shows a loader when clicked.
        // And a dialog object will be passed to the then() callback
      })
      .then((dialog) => {
        this.extraModel.deleteImage(this.$route.params.id, item).then((message) => {
          console.log(message);
          dialog.close();
          this.snackbar.show = true;
          this.snackbar.color = 'green';
          this.snackbar.message = `La imagen se elimino correctamente`;
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
          dialog.close();
          this.snackbar.show = true;
          this.snackbar.color = 'red';
          this.snackbar.message = `Error al eliminar la imagen, por favor contacte al Administrador si el problema persiste`;
        });
      })
      .catch((e) => {
        console.log(e);
        // Triggered when cancel button is clicked
        console.log('Delete aborted');
      });
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'green';
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'red';
    },
  },
  created () {
    this.$store.commit('SET_LAYOUT', 'admin')
  },
  computed:{
  },
  mounted() {
    let unsubscribe; 
    this.extraModel.findById(this.$route.params.id).onSnapshot(doc => {
      this.extra = {
        adult:doc.data().adult,
        child:doc.data().child,
        elderly:doc.data().elderly,
        infant:doc.data().infant,
        name:doc.data().name,
        status:doc.data().status,
      }
      this.ref = doc.ref;
      if (unsubscribe) {
        unsubscribe();
      }
      unsubscribe = this.ref.collection('extraImages').withConverter(ExtraImageDataConverter).onSnapshot(docs => {
        this.items = [];
        docs.forEach(doc => {
          this.items.push(doc.data());
        })
      })
    })
  },
}
</script>
<style>
  .file-input-width{
    flex-basis: calc(100% - 200px);
  }
</style>